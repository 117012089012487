<template>
  <div>
    <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="50"
      md-mode="indeterminate" />
    <div id="zoomlevel"></div>
    <div id="map2" class="map2"></div>
    <div class='md-layout-item'>{{ $t('budget.selected') }} {{ $t(selection_info) }}
      <a v-if="(selection_url)" href="#" @click.stop.prevent="onSelectionClick()">{{ $t(selection_url.title) }}</a>
    </div>
    <div id="legend" v-show="showLegendButton" class="ol-legend ol-unselectable ol-control">
      <button @click="toggleLegend">...</button>
      <div v-if="showLegendBox" class="legend-box">
        <div class="legend-label" v-for="elem in vectorLegend" :key="elem.name">
          <span>
            <md-icon :style="{ color: elem.colour }">circle</md-icon>
            {{ $t(elem.name) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { arrayCompare, getRangeVal } from './helper'
import olMap from './olMap'
import { getLegend } from '../../store/maps/ranges_helper'

export default {
  props: {
    tileLayer: { type: String, default: 'osm' },
    mapDefaultParams: { type: Object },
    filterOptions: { type: Object },
    trackedEvents: { type: Array }
  },
  components: {
  },
  watch: {
    tileLayer(/*newVal*/) {
      this.changeTileLayer()
    },
    vectorLayer(newVal, oldVal) {
      this.setVisible({ layer: oldVal })
      this.setVisible({ layer: newVal, show: true })
      if (newVal === 'roads') this.parameter = 'road_class'

      this.selection_url = {}
      this.$store.commit('SET_SELECTION_INFO', 'map.nothing')
    },
    filterValue(newVal, oldVal) {
      console.log('index.vue filterValue', newVal, oldVal)
      this.onLoadLayerData('vectorLayer')
    },
    parameter(newVal, oldVal) {
      console.log('index.vue parameter', newVal, oldVal)
      if (this.vectorLayer !== 'roads') this.onLoadLayerData('vectorLayer')
    },
    currExtent(newValue) {
      this.refreshSizeByExtent()
    }
  },
  data() {
    return {
      vectorLayer: 'roads',
      showSpinner: false,

      initialZoomLevel: 11,
      initialExtent: null,

      currZoomLevel: 11,
      currExtent: null,
      roadUrlHash: '',
      zoomRangeVal: 0,
      vectorZoomRange: [13, 16],
      popupBoxVisible: false,
      popupContent: [],
      mapLayerTypes: {},
      viewTypesStyle: {},
      showLegendBox: true,
      ol: undefined,
      simUpdatedLayers: [],

      parameter: '',
      filter: '',
      filterValue: '',
      selection_url: {},
    }
  },

  beforeDestroy() {
    this.$eventHub.$off('viewGridClicked')
    this.$eventHub.$off('resetStatus')
    this.$eventHub.$off('vectorLayerChanged')
    this.$eventHub.$off('paramTypeChanged')
    this.ol.destroy()
  },

  async created() {
    //const {state} = this.$route.query

    const {
      map_centre,
      max_zoom,
      data_projection,
      view_projection,
      zoom
    } = this.mapDefaultParams

    this.ol = new olMap({
      center: map_centre,
      zoom: zoom,
      extent: this.currExtent,
      viewProjection: view_projection,
      dataProjection: data_projection,
      viewMaxZoomDefault: max_zoom,
      vectorLayerList: ['bridges', 'tunnels', 'roads', 'vectorLayer',]   //'pavedRoadsLayer']
    })

    this.showSpinner = true
    this.$store.dispatch('LOAD_BASEMAP_TYPES').then((res) => {
      const { basemap_tiles } = res
      const tileList = this.ol.createTileLayers(basemap_tiles)
      this.$emit('changeTileList', tileList)
      this.changeTileLayer()
      //this.onUpdateAllLayers()
    })

    this.setVisible({ layer: 'vectorLayer' })

    // loading coords for bridges and tunnels once
    await this.$store.dispatch('LOAD_COORDS', 'bridges').then((geoJSON) => {
      this.updateLayerData({ layer: 'bridges', source: geoJSON })
      this.setVisible({ layer: 'bridges' })
    })
    await this.$store.dispatch('LOAD_COORDS', 'tunnels').then((geoJSON) => {
      this.updateLayerData({ layer: 'tunnels', source: geoJSON })
      this.setVisible({ layer: 'tunnels' })
    })
    const params = {
      zoom: Math.trunc(this.ol.getZoom()),
      start_x: 80347,
      start_y: 4443524,
      end_x: 1027105,
      end_y: 4767255
    }
    await this.$store.dispatch('LOAD_ROADS_COORDS', params).then(() => {
      this.updateLayerData({ layer: 'roads', source: this.roadsGeoJSON })
    })

    this.$eventHub.$on('viewGridClicked', this.onViewGridClicked)
    this.$eventHub.$on('vectorLayerChanged', this.onVectorLayerChange)
    this.$eventHub.$on('paramTypeChanged', this.onParamTypeChanged)
    this.$eventHub.$on('resetStatus', this.onResetStatus)

    this.showSpinner = false
  },

  mounted() {
    const fnEvents = [
      { type: 'singleclick', fn: this.onMapClick },
      { type: 'pointermove', fn: this.onPointerMove }
    ]
    this.$nextTick(function () {
      this.ol.init({ overlayElem: 'popup', mapElem: 'map2' })

      this.refreshSizeByExtent()

      this.initialExtent = this.ol.getMapExtent()
      this.initialZoomLevel = this.ol.getZoom()

      this.currExtent = this.ol.getMapExtent()
      this.currZoomLevel = this.ol.getZoom()

      this.trackedEvents.forEach((event) => {
        const evtIndex = fnEvents.findIndex((item) => item.type === event)
        if (~evtIndex) {
          this.ol.setEventHandler(
            fnEvents[evtIndex].type,
            fnEvents[evtIndex].fn
          )
        }
      })
    })

  },
  computed: {
    showLegendButton() {
      return Boolean(this.vectorLegend)
    },
    vectorLegend() {
      return getLegend((!this.parameter || this.parameter === 'road_class') ? this.vectorLayer : this.parameter)
    },
    roadsGeoJSON() {
      return this.$store.state.MapComponent.roadsGeoJSON
    },
    paramsGeoJSON() {
      return this.$store.state.MapComponent.paramsGeoJSON
    },
    selection_info() {
      return this.$store.state.MapComponent.selection_info
    },
  },
  methods: {
    async onVectorLayerChange(value) {
      //this.vectorLayer = value
      this.onResetStatus(value)
    },

    async onViewGridClicked({ layer, filter, value, desc }) {
      console.log('index.vue viewGridClicked', layer, filter, value, desc)
      this.selection_url = {}
      const status = (['road_class', 'fk_region', 'fk_road', 'section_id', 'fk_deu'].includes(filter)) ? desc : 'map.nothing'
      this.$store.commit('SET_SELECTION_INFO', status)

      this.filter = filter
      this.filterValue = value
      this.vectorLayer = layer
    },

    async onParamTypeChanged({ layer, value }) {
      console.log('index.vue onParamTypeChanged', layer, value)
      this.vectorLayer = layer
      this.parameter = value
    },

    async onResetStatus(vectorLayer) {
      this.showSpinner = true
      //console.log('index.vue onResetStatus: initialExtent. currExtent', this.initialExtent, this.currExtent)

      this.ol.map.getView().setCenter(this.mapDefaultParams.map_centre)
      this.ol.map.getView().setZoom(this.initialZoomLevel)
      this.currZoomLevel = this.initialZoomLevel
      this.currExtent = this.initialExtent

      this.selection_url = {}
      this.$store.commit('SET_SELECTION_INFO', 'map.nothing')
      this.vectorLayer = vectorLayer ? vectorLayer : 'roads'
      //this.parameter = 'road_class'
      this.showSpinner = false
    },

    async setVisible(payload) {
      await this.ol.setVisible(payload)
    },

    async updateLayerData(payload) {
      this.showSpinner = true
      await this.ol.updateVectorSource(payload)
      this.showSpinner = false
    },

    onSelectionClick() {
      this.$router.push({ path: `${this.selection_url.href}` })
    },

    toggleLegend() {
      this.showLegendBox = !this.showLegendBox
    },

    onUpdateAllLayers() {
      this.onLoadLayerData('vectorLayer')
    },

    refreshSizeByExtent() {
      this.ol.fitMapByExtent(this.currExtent)
    },

    changeTileLayer() {
      this.ol.changeTileLayer(this.tileLayer)
    },
    
    onPopupBoxClose() {
      this.popupBoxVisible = false
    },

    async onPointerMove(evt) {
      if (evt.dragging) return
      //   this.setSelectedFeature(evt, 'pointerMove')
    },

    async onMapClick(evt) {
      //console.log("mapclick: ", evt)
      this.setSelectedFeature(evt, 'click')
    },

    async setSelectedFeature(evt, typeEvt) {
      if (typeEvt !== 'click') return
      const selectedPointInfo = this.ol.getFeatureInfoByCoord(evt)

      let item_id = null
      let clicked_coords = null

      let desc = 'map.nothing'
      let content = {}
      if (selectedPointInfo) {
        console.log('index.vue setSelectedFeature', selectedPointInfo.feature_data, this.vectorLayer)
        item_id = selectedPointInfo.feature_data[2]
        content = { title: this.$t(selectedPointInfo.feature_data[1].link), href: selectedPointInfo.feature_data[1].href }
        desc = (this.vectorLayer === 'vectorLayer') ? `${this.$t('map.roads')} - ` : `${this.$t('map.' + this.vectorLayer)} - `
        if (this.vectorLayer === 'roads') {
          clicked_coords = selectedPointInfo.feature_data[3]
          this.filter = 'section_id'
          this.filterValue = item_id
        } else desc += selectedPointInfo.feature_data[0]
      }
      this.$store.commit('SET_SELECTION_INFO', desc)
      this.selection_url = content

      this.$eventHub.$emit('mapItemSelected', { layer: this.vectorLayer, item_id: item_id, clicked_coords: clicked_coords })
    },

    initMap() { },
    getLayerFeatures(layer) {
      return this.ol.getFeatures(layer)
    },

    async onMapEvent(/*evt*/) {
      /*
      let onUpdateData = false
      let onUpdateTile = false

      const newViewExtent = this.ol.getMapExtent()
      const newZoomLevel = this.ol.getZoom()
      const newZoomRounded = Math.round(newZoomLevel)
      const currZoomRounded = Math.round(this.currZoomLevel)
      const tileZoomRange = this.ol.getTileLayerZoomRange()
      const vectorZoomRange = this.vectorZoomRange

      if (this.vectorLayer === 'vectorLayer' && (getRangeVal(newZoomRounded, vectorZoomRange) !==
        getRangeVal(currZoomRounded, vectorZoomRange) || newZoomRounded < currZoomRounded)) {
        onUpdateData = true
      }

      if (tileZoomRange.length > 0 && getRangeVal(currZoomRounded, tileZoomRange) !==
        getRangeVal(newZoomRounded, tileZoomRange)) {
        onUpdateTile = true
      }

      if (this.vectorLayer === 'vectorLayer' && !arrayCompare(this.currExtent, newViewExtent)) {
        onUpdateData = true
      }

      this.currExtent = newViewExtent
      this.currZoomLevel = newZoomLevel

      const zoomInfo = `Zoom level= ${this.currZoomLevel}; rounded= ${newZoomRounded}`
      document.getElementById('zoomlevel').innerHTML = zoomInfo

      //this.$emit('change-map-state', { extent: [...newViewExtent] })

      if (onUpdateTile) {
        this.changeTileLayer()
      }

      if (onUpdateData) {
        //this.onUpdateAllLayers()
        //      this.onLoadLayerData('vectorLayer')
      }
      */
      console.log('index.vue onMapEvent, onUpdateData')
    },

    async onClearLayerData(layer) {
      this.ol.clearVectorSource(layer)
    },

    async onLoadLayerData(layer) {
      console.log('this.filter, this.parameter', this.filter, this.parameter)
      if (!this.filter && this.parameter) return

      this.showSpinner = true
      const mapViewExtent = this.ol.getMapExtent()
      const mapViewZoom = Math.trunc(this.ol.getZoom())

      var payload = {
        zoom: mapViewZoom,
        /* // Finish - check when switching to another road/section should be another extent
          start_x: Math.round(mapViewExtent[0]),      //80347,
          start_y: Math.round(mapViewExtent[1]),      //4443524,
          end_x: Math.round(mapViewExtent[2]),        //1027105,
          end_y: Math.round(mapViewExtent[3]),         //4767255
      */
        //if no filter is give the browser will hang - resolve later when API fill everything
        data_100_column_name: (this.filter) ? this.parameter : null
      }
      const value = { value: this.filterValue }
      if (this.vectorLayer === 'vectorLayer') switch (this.filter) {
        case 'fk_region':
          Object.defineProperty(payload, 'region_id', value)
          break
        case 'fk_road':
          Object.defineProperty(payload, 'road_id', value)
          break
        case 'fk_deu':
          Object.defineProperty(payload, 'deu_id', value)
          break
        case 'section_id':    //'section_description':
          Object.defineProperty(payload, 'section_id', value)
          break
        default:
          //Object.defineProperty(payload, 'region_id', value)
          break
      }
      //     if (this.parameter) {
      //       Object.defineProperty(payload, 'data_100_column_name', { value: this.parameter })
      //     }
      if (payload) try {
        await this.$store.dispatch('LOAD_ROADS_COORDS', payload).then(() => {
          console.log('paramsGeoJSON', this.paramsGeoJSON)
          this.updateLayerData({ layer: layer, source: this.paramsGeoJSON }).then(() => {
            this.currExtent = this.$store.state.MapComponent.mapExtent
            this.refreshSizeByExtent()
          })
        })
      } catch (err) { console.error(`Error loading roads coords: ${err}`) }
      this.showSpinner = false
    }
  }
}
</script>

<style lang="scss">
#map2 {
  width: 100%;
  height: 100%;

  canvas {
    max-width: unset;
  }

  &:hover {
    .ol-mouse-position {
      background: white;
    }
  }
}

.ol-full-screen {
  // left: unset;
  // right: 0.5em;
  // top: 3.8em;
}

.ol-zoom {
  top: 2.5em;
  left: 0.5em;
}

.ol-legend {
  right: 0.5em;
  top: 2.8em;

  .legend-box {
    position: absolute;
    padding: 5px;
    margin-top: 7px;
    border-radius: 10px;
    right: 0.2em;
    background-color: rgba(255, 255, 255, 0.7);

    //width: 100px;
    //height: 100px;
    .legend-label {
      font-size: 12px;
      display: flex;
      align-items: center;

      i {
        margin-top: 3px;
      }

      span {
        white-space: nowrap;
        padding-left: 5px;
        font-weight: bold;
      }
    }
  }
}

.ol-mouse-position {
  border-radius: 5px;
  padding: 1px 3px;
  top: 8px;
  right: unset;
  left: 8px;
  position: absolute;
  //background: white;
}

.ol-zoomslider {
  top: 6.3em;
}

#zoomlevel {
  display: none;
}

.popupbox-content {
  text-align: center;
}
</style>
